<template>
  <v-container>
    <v-row>
      <v-col>
        <div class="font-weight-bold mt-10 title-page" tabindex="0">Habilitações e Divergências</div>
      </v-col>
    </v-row>

    <br />

    <div class="mt-4" v-if="dataset.loading">
      <v-progress-circular indeterminate color="primary" :size="50"></v-progress-circular>
    </div>

    <div v-if="!dataset.loading" v-html="!user ? dataset.contentUserAnonymous : dataset.contentUserLogged" tabindex="0">
    </div>

    <v-row v-if="!user" no-gutters>
      <v-col>
        <br>
        <iframe width="600" aria-label="Video de demonstração de como proceder" alt="" aria-hidden="true"
          arial-label="Video demonstrativo para criar uma habilitação ou divergência" height="500"
          src="https://www.youtube.com/embed/6okzXsdt0k0" frameborder="0" allowfullscreen></iframe>
      </v-col>
    </v-row>

    <br>
    <v-divider></v-divider>
    <br>

    <div v-if="user">
      <!-- <v-row>
        <v-col cols="12">
          <span class="mr-3"> <strong>Selecione a empresa a qual você gostaria de verificar se está incluído na lista de credores</strong></span>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon small :color="'primary'" v-bind="attrs" v-on="on">mdi-help-circle</v-icon>
            </template>
<span>Selecione a empresa a qual gostaria de habilitar ou divergir de um crédito</span>
</v-tooltip>
</v-col>
</v-row>
<v-row>
  <v-col cols="12" class="mt-n3">
    <v-autocomplete :disabled="dataset.loading" dense required :items="dataset.clients" label="Devedora"
      v-model="dataset.data.client" item-value="id" item-text="name" @change="onClientChange" outlined
      hide-details="auto" no-data-text="Nenhuma devedora encontrada">
    </v-autocomplete>
  </v-col>
</v-row> -->
      <template v-if="isTimely">
        <v-row>
          <v-col>
            <div class="red--text">
              <span>
                <strong>ATENÇÃO:</strong> Você está classificado como tempestivo, e o pedido será entregue dentro do
                prazo estabelecido.
              </span>
            </div>
          </v-col>
        </v-row>
      </template>



      <v-row v-if="data.length">
        <v-col>
          <span v-if="!dataset.isAssociationCreditor">
            Atualmente, você não está incluído na lista. Você apresentou o pedido de alteração da lista abaixo:
          </span>
          <span v-else>
            Atualmente, você está incluído na lista pelo valor <strong>{{ (dataset.userRequestedValue || 0) | currency
              }}</strong> Você apresentou o pedido de alteração da lista abaixo:
          </span>
        </v-col>
      </v-row>

      <v-row v-if="
        !data.length &&
        (!user.creditorData ||
          !user.creditorData.approved_amount_at ||
          user.creditorData.approved_amount_at === '0000-00-00 00:00:00')
      " class="mt-4">
        <v-col v-if="dataset.isAssociationCreditor">
          <p tabindex="0">
            <!-- <v-icon color="accent">mdi-check</v-icon> -->
            <!-- <span v-if="(!user.value_accepted_at || user.value_accepted_at === '0000-00-00 00:00:00') && !data.length"> -->
            <span v-if="
              (user.creditorData &&
                (user.creditorData.approved_amount_at === '0000-00-00 00:00:00' ||
                  !user.creditorData.approved_amount_at)) &&
              !data.length
            ">
              <v-icon slot="icon" color="warning" size="36">
                mdi-alert-outline
              </v-icon>
              <span tabindex="0">Você já está incluído na lista de credores com o valor de</span>
              {{ (dataset.userRequestedValue || 0) | currency }}.
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon small :color="'primary'" v-bind="attrs" v-on="on">mdi-help-circle</v-icon>
                </template>
                <span tabindex="0">Clique no link abaixo para Concordar ou Dicordar deste valor.</span>
              </v-tooltip>
            </span>

            <br />
            <br />
            <!-- <span v-if="(!user.value_accepted_at || user.value_accepted_at === '0000-00-00 00:00:00') && !data.length"> -->
            <span v-if="
              (!user.creditorData || !user.creditorData.approved_amount_at ||
                user.creditorData.approved_amount_at === '0000-00-00 00:00:00') &&
              !data.length
            ">
              <a href="/#/habilitacoes-divergencias/novo"
                aria-label="Link para concordar ou discordar do valor apresentado"><strong>Clique aqui</strong></a>
              para <strong>Concordar</strong> ou <strong>Discordar</strong> do valor apresentado.
            </span>
            <!-- <span v-if="(!user.value_accepted_at || user.value_accepted_at === '0000-00-00 00:00:00') && !data.length">
              Caso não concorde, você pode divergir. <a href="/#/habilitacoes-divergencias/novo"><strong>Clique
                  aqui</strong></a></span> -->
          </p>
        </v-col>
        <v-col v-else>
          <p tabindex="0">
            <v-icon color="warning">mdi-clock-time-two-outline</v-icon> Você não está incluído na
            lista de credores.
            <span v-if="!data.length">Caso queira se habilitar,
              <a href="/#/habilitacoes-divergencias/novo"><strong>clique aqui</strong></a></span>
          </p>
        </v-col>
      </v-row>

      <!-- <v-row v-if="user.requested_value"> -->
      <v-row v-if="user.creditorData && user.creditorData.approved_amount_at">
        <!-- <v-col v-if="user.value_accepted_at && user.value_accepted_at !== '0000-00-00 00:00:00'"> -->
        <v-col v-if="
          user.creditorData.approved_amount_at &&
          user.creditorData.approved_amount_at !== '0000-00-00 00:00:00'
        ">
          <p tabindex="0">
            <v-icon color="accent">mdi-currency-usd</v-icon> Você concordou com o valor de
            <!-- {{ user.requested_value | currency }} no dia {{ user.value_accepted_at | defaultDate("DD/MM/YYYY HH:mm:ss") }} -->
            {{ user.creditorData.approved_amount | currency }} no dia
            {{ user.creditorData.approved_amount_at | defaultDate('DD/MM/YYYY HH:mm:ss') }}
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" id="btnDiscord" type="button" elevation="0" color="#FFAB40"
                  class="ml-10" small dark @click="onDisagree()" tabindex="0"
                  aria-label="Botão para revogar aprovação de credito">
                  <v-icon left>mdi-restore</v-icon>
                  Revogar Aprovação
                </v-btn>
              </template>
              <span>Desfazer concordância do valor</span>
            </v-tooltip>
          </p>
        </v-col>
      </v-row>

      <template v-if="processes.length > 0">
        <v-row v-for="(process, processIndex) in processes" :key="process.id">
          <v-col cols="12" class="mb-n3">
            <strong>Processo #{{ processIndex + 1 }}</strong>
          </v-col>
          <v-col cols="12">
            <v-expansion-panels>
              <v-expansion-panel>
                <v-expansion-panel-header class="custom-panel-header">
                  <p><strong>Número:</strong> {{ process.number }}</p>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <p><strong>Local:</strong> {{ process.local }}</p>
                  <p><strong>Tribunal:</strong> {{ process.court }}</p>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-col>
        </v-row>
      </template>

      <br />
      <template v-if="data.length">
        <div v-if="isDocRequested">
          <v-alert dark color="#FF6F00" icon="mdi-alert-outline">
            Há uma solicitação de novos documentos! Para mais informações, verifique a caixa de e-mail registrada no seu
            cadastro.
          </v-alert>
        </div>
        <div v-for="(item, i) in data" :key="i">
          <v-row>
            <v-col>
              <strong>Habilitação/Divergência #{{ i + 1 }}</strong>
              <br />
              <strong>Solicitado em: </strong>
              <span>{{ $moment(item.created_at).utc().format('DD/MM/YYYY') }}</span>
            </v-col>
            <v-col>
              <v-row align="center" justify="end" class="mr-1">
                <v-col cols="auto">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn v-bind="attrs" v-on="on" v-if="item.status == 0" id="btnEdit" type="button" elevation="0"
                        tabindex="0" aria-label="Botão para editar os dados de uma habilitação ou divergência"
                        color="primary" style="height: 30px;" dark @click="editItem(item)">
                        <v-icon left>mdi-pencil</v-icon>
                        Editar
                      </v-btn>
                    </template>
                    <span>Editar informaçãoes desta habilitação / divergência</span>
                  </v-tooltip>
                </v-col>

                <v-col cols="auto">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn v-bind="attrs" v-on="on" v-if="item.status == 0" id="btnDelete" type="button" elevation="0"
                        tabindex="0" aria-label="Botão para remover uma habilitação ou divergência" color="important"
                        style="height: 30px;" dark @click="openDeleteDialog(item)">
                        <v-icon left>mdi-trash-can</v-icon>
                        Remover
                      </v-btn>
                    </template>
                    <span>Remover esta habilitação / divergência</span>
                  </v-tooltip>
                </v-col>

                <v-col cols="auto">
                  <v-alert :color="getColorByStatus(item.client.event_report_file ? item.status : 0)" text-color="white"
                    shaped dense dark class="mt-4" style="max-width: 200px; height: 35px;">
                    {{ getTextByStatus(item.client.event_report_file ? item.status : 0) }}
                  </v-alert>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-expansion-panels>
                <v-expansion-panel v-for="(eventItem, eventIndex) in item.items" :key="eventIndex">
                  <v-expansion-panel-header aria-label="Formulario com os dados do negócio">
                    <div>
                      <strong>Origem:</strong>
                      <span>
                        {{ truncateText(eventItem.origin || 'N/A', 30) }}
                      </span>
                      /
                      <strong>Valor:</strong>
                      <span v-if="eventItem.amount">
                        <!-- {{ eventItem.currency | enum(dataset.currencyTypes, "id", "currencyFormat") }} -->
                        <!-- {{ eventItem.amount | currency }} -->
                        {{ formatValue(eventItem.amount, eventItem.currency) }}
                      </span>
                      <span v-else>N/A</span>
                      <template v-if="eventItem.partial_amount">
                        /
                        <strong>Valor aprovado:</strong>
                        <span v-if="eventItem.partial_amount">
                          <!-- {{ eventItem.currency | enum(dataset.currencyTypes, "id", "currencyFormat") }} -->
                          {{ eventItem.partial_amount | currency }}
                        </span>
                      </template>
                    </div>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-row>
                      <v-col>
                        <p tabindex="0">
                          <span class="section-info-title">Devedora</span>
                          <br />
                          <span class="section-info-value text-uppercase">
                            <!-- {{ item.client.name }} -->
                            {{ eventItem.company_name }}
                          </span>
                        </p>
                        <p tabindex="0">
                          <span class="section-info-title">Origem</span>
                          <br />
                          <span class="section-info-value text-uppercase">
                            {{ eventItem.origin }}</span>
                        </p>
                        <p v-if="eventItem.request_number" tabindex="0">
                          <span class="section-info-title">Nº de Identificação</span>
                          <br />
                          <span class="section-info-value text-uppercase">{{
                            eventItem.request_number
                            }}</span>
                        </p>
                        <p tabindex="0">
                          <span class="section-info-title">Moeda</span>
                          <br />
                          <span class="section-info-value text-uppercase">
                            {{ eventItem.currency | enum(dataset.currencyTypes, 'id', 'name') }}
                          </span>
                        </p>
                        <p tabindex="0">
                          <span class="section-info-title">Valor</span>
                          <br />
                          <span class="section-info-value text-uppercase">
                            {{ formatValue(eventItem.amount, eventItem.currency) }}
                          </span>
                        </p>

                        <p v-if="eventItem.partial_amount" tabindex="0">
                          <span class="section-info-title">Valor aprovado</span>
                          <br />
                          <span class="section-info-value text-uppercase">
                            {{ eventItem.partial_amount | currency }}
                          </span>
                        </p>
                        <p tabindex="0">
                          <span class="section-info-title">Observações</span>
                          <br />
                          <span class="section-info-value text-uppercase">{{
                            eventItem.observation
                            }}</span>
                        </p>
                        <p tabindex="0">
                          <span class="section-info-title">Documento(s)</span>
                          <br />
                          <span class="section-info-value">{{ eventItem.docs.length }} arquivo(s)</span>
                        </p>
                        <v-row>
                          <v-col v-for="(itemDoc, docIndex) in eventItem.docs" :key="docIndex">
                            <v-card>
                              <v-img @click="openDoc(itemDoc.doc.file_url)" class="rounded-xl"
                                lazy-src="https://picsum.photos/id/11/10/6" :height="70" :width="70"
                                :src="itemDoc.doc.file_url"></v-img>
                              <v-card-text class="pa-0 file-text">
                                {{ itemDoc.doc.name }}
                              </v-card-text>
                            </v-card>
                          </v-col>
                        </v-row>

                        <template v-if="item.status !== 0">
                          <p class="mt-6">
                            <span class="section-info-title">Motivo</span>
                            <br />
                          </p>
                          <div v-html="completeReason ? item.reason : item.small_reason"></div>
                          <v-row>
                            <v-col>
                              <v-btn color="primary" outlined @click.native="completeReason = !completeReason">
                                {{
                                  completeReason ? 'Ocultar motivo completo' : 'Ver motivo completo'
                                }}</v-btn>
                            </v-col>
                          </v-row>
                        </template>
                      </v-col>
                    </v-row>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-col>
          </v-row>
          <v-row>
            <v-col v-if="item.items.length > 1">
              <v-divider></v-divider>
            </v-col>
          </v-row>
        </div>
      </template>

      <v-row no-gutters>
        <v-col>
          <br>
          <iframe width="600" height="500" src="https://www.youtube.com/embed/6okzXsdt0k0" frameborder="0"
            aria-hidden="true" arial-label="Video demonstrativo para criar uma habilitação ou divergência"
            allowfullscreen aria-label="Video de demonstração de como proceder" alt=""></iframe>
        </v-col>
      </v-row>

      <v-dialog v-model="dialogViewDoc" max-width="60%" @keydown.esc="cancel">
        <v-card>
          <v-img :src="selectedDoc" alt="" contain />
        </v-card>
      </v-dialog>

      <v-dialog v-model="dialog" max-width="500">
        <v-card>
          <v-card-title class="blue--text text--darken-2"
            style="border-bottom: 4px solid #007bff; padding-bottom: 8px;">
            Excluir habilitação / divergência
          </v-card-title>
          <br>
          <v-card-text tabindex="0">
            Deseja <b>excluir</b> esta habilitação / divergência?<br><br>
            <span class="font-weight-bold">OBS.:</span> Esta operação é irreversível! Caso necessário, outra habilitação
            /
            divergência poderá ser criada.
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="grey" tabindex="0" text @click="dialog = false"
              aria-label="Botão para cancelar operação e não remover a habilitação ou divergência">Cancelar</v-btn>
            <v-btn color="red" tabindex="0" text @click="confirmDelete()"
              aria-label="Botão para confirmar a remoção da habilitação ou divergência">Excluir</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

    </div>
  </v-container>
</template>

<script>
import 'moment/locale/pt-br'
import { eventHub } from '@/main'
import currencyTypes from '@/common/data/currency-types.data'
import axios from 'axios'

export default {
  data: () => ({
    user: null,
    data: [],
    loading: false,
    dialog: false,
    selectedItem: null,
    isDocRequested: null,
    dataset: {
      contentUserAnonymous: '',
      contentUserLogged: '',
      currencyTypes: currencyTypes,
      isAssociationCreditor: false,
      userRequestedValue: 0,
      loading: false,
      clients: [],
      data: {
        items: [],
        client: null
      }
    },
    completeReason: false,
    selectedDoc: null,
    dialogViewDoc: false,
    processes: [],
    isTimely: false
  }),

  created() {
    this.$ga.event({
      eventCategory: 'page',
      eventAction: 'action',
      eventLabel: 'click',
      eventValue: 'user-area'
    })

    this.loadData()
  },

  methods: {
    async loadData() {
      this.dataset.loading = true

      try {
        await this.loadText()
        // await this.loadClients();

        if (this.$root.user) {
          await this.verifyAssociation()
          await this.loadUserInfo()
          await this.load()
        }
      } catch (error) {
        this.$dialog.notify.error(this.$codes(error), {
          position: 'top-right',
          timeout: 2000
        })
      } finally {
        this.dataset.loading = false
      }
    },

    formatValue(value, currency) {
      if (value === null || value === undefined) {
        return 'N/A'
      }

      let options = {
        style: 'currency',
        currency: 'BRL'
      }

      switch (currency) {
        case 1:
          options.currency = 'BRL'
          break
        case 2:
          options.currency = 'USD'
          break
        case 3:
          options.currency = 'EUR'
          break
        default:
          options.currency = 'BRL'
      }

      return new Intl.NumberFormat('pt-BR', options).format(value)
    },

    // onClientChange(value) {

    //   console.log('Cliente selecionado:', value);

    // },

    truncateText(text, maxLength) {
      return text.length > maxLength ? `${text.slice(0, maxLength)}...` : text
    },

    openDoc(doc) {
      window.open(doc, '_blank')
    },

    async verifyAssociation() {
      const result = await axios.get('/api/user-creditors/association')

      const data = result.data.content

      // if (!data) return;

      if (!data || data.length === 0) return

      this.dataset.isAssociationCreditor = true
      this.dataset.userRequestedValue = data[0].amount
    },

    async loadText() {
      const result = await axios.get(`/api/dynamic-infos/keys`, {
        params: { keys: 'user-event-anonymous,user-event-logged' }
      })

      if (!result.data.content) return

      let contentUserAnonymous = result.data.content.find(x => x.key === 'user-event-anonymous')
      let contentUserLogged = result.data.content.find(x => x.key === 'user-event-logged')

      this.dataset.contentUserAnonymous = contentUserAnonymous ? contentUserAnonymous.value : ''
      this.dataset.contentUserLogged = contentUserLogged ? contentUserLogged.value : ''
    },

    async loadUserInfo() {
      try {
        const result = await axios.get('/api/users/info')

        this.user = result.data.content

        this.isTimely =
          this.user.timely_date && this.user.timely_date !== '0000-00-00 00:00:00';

      } catch (error) {
        console.error('Erro ao carregar informações do usuário:', error);
        this.$dialog.notify.error(this.$codes(error), {
          position: 'top-right',
          timeout: 2000,
        });
      }
    },

    // async loadClients() {

    //   const result = await axios.get(`/api/clients`, { params: { limit: 100000 } });

    //   if (!result.data.content.data) return;

    //     this.dataset.clients = result.data.content.data;
    // },

    async load() {
      const result = await axios.get('/api/user-events/request')

      this.data = result.data.content

      this.processes = [];

      this.data.forEach(item => {
        if (Array.isArray(item.processes) && item.processes.length > 0) {
          this.processes = [...this.processes, ...item.processes]
        }
      })

      this.data.forEach((record) => {

        const requestedDocAt = record.requested_doc_at
          ? new Date(record.requested_doc_at)
          : null;

        if (record.items && record.items.length > 0) {
          const mostRecentItemDate = record.items.reduce((latest, item) => {
            const itemDate = new Date(item.updated_at);
            return itemDate > latest ? itemDate : latest;
          }, new Date(0));

          if (requestedDocAt && requestedDocAt > mostRecentItemDate) {
            this.isDocRequested = true;
          } else {
            this.isDocRequested = false;
          }
        }
      });

    },

    getColorByStatus(status) {
      switch (status) {
        case 0:
          return 'warning'
        case 1:
          return 'accent'
        case 2:
          return 'important'
        case 3:
          return 'primary'

        default:
          break
      }
    },

    getTextByStatus(status) {
      switch (status) {
        case 0:
          return 'Aguardando análise'
        case 1:
          return 'Aprovado'
        case 2:
          return 'Recusado'
        case 3:
          return 'Aprovado parcial'

        default:
          break
      }
    },

    editItem(item) {
      this.$router.push(`habilitacoes-divergencias/${item.id}`)
      window.scrollTo(0, 0)
    },

    openDeleteDialog(item) {
      this.selectedItem = item;
      this.dialog = true;
    },

    async confirmDelete() {
      this.dialog = false;
      if (this.selectedItem) {
        await this.deleteItem(this.selectedItem);
      }
    },

    async deleteItem(item) {

      this.dataset.loading = true;

      try {
        const response = await axios.delete(`/api/user-events/request/delete/${item.id}`)

        this.$dialog.notify.success("Habilitação / divergência excluída com sucesso!", { position: 'top-right', timeout: 5000 });

        this.load()

      } catch (error) {
        if (error.response && error.response.status === 400) {
          this.$dialog.notify.error(error.response.data.message, { position: 'top-right', timeout: 5000 });
        } else {
          this.$dialog.notify.error("Erro ao excluir a habilitação / divergência!", { position: 'top-right', timeout: 5000 });
        }
      } finally {
        this.dataset.loading = false;

        this.selectedItem = null;
      }

    },

    async onDisagree() {

      const proceedAction = await this.$dialog.confirm({
        text: 'Deseja revogar a concordância do valor efetuada anteriormente?',
        title: 'Revogar concordância',
        actions: {
          false: 'Cancelar',
          true: 'revogar'
        }
      });

      if (!proceedAction) return;

      this.dataset.loading = true;

      try {
        const response = await axios.post('/api/user-creditors/restore-approved-amount')

        this.$dialog.notify.success("Concordância de crédito revogada com sucesso!", { position: 'top-right', timeout: 5000 });

        this.loadData()

      } catch (error) {
        this.$dialog.notify.error(this.$codes(error), { position: 'top-right', timeout: 5000 });
      } finally {
        this.dataset.loading = false;
      }

    },


    viewCreateEvent() {
      this.$router.push(`habilitacoes-divergencias/novo`)
    }
  }
}
</script>

<style lang="scss">
.title-page {
  color: var(--v-primary-base);
  font-family: 'Raleway';
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: 38px;
}

.section-info-title {
  color: var(--v-primary-base);
  font-family: 'Raleway';
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
}

.section-info-value {
  font-family: 'Raleway';
  font-size: 14px;
  font-style: normal;
}

.custom-panel-header {
  min-height: 20px !important;
  line-height: 20px !important;
  padding-top: 5 !important;
  padding-bottom: 0 !important;
}
</style>
