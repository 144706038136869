<template>
    <v-container>

        <v-row>
            <v-col>
                <div class="font-weight-bold mt-10 title-page" tabindex="0">Relatórios Mensais</div>
            </v-col>
        </v-row>

        <br />

        <div class="mt-4" v-if="dataset.loading">
            <v-progress-circular indeterminate color="primary" :size="50"></v-progress-circular>
        </div>

        <div v-html="dataset.description" tabindex="0"></div>

        <!-- <p class="text-justify">
                Os Relatórios Mensais de Atividades das Devedoras (RMAs) são instrumentos elaborados pela Administração Judicial e juntados aos autos da Recuperação Judicial.
                <br>
                <br>
                Por meio dos RMAs o Juízo, os credores e demais interessados tomam ciência das atividades das Devedoras, seu cenário econômico-financeiro, bem como do andamento de suas operações.
                <br>
                <br>
                 O RMA é elaborado com base nos documentos fornecidos pelas Devedoras e tem por objetivo fiscalizar sua veracidade e conformidade, nos termos do art. 22, inciso II, ‘c’ da Lei 11.101/05.
            </p> -->

        <v-row>
            <v-col>
                <span class="page-subtitle" tabindex="0">Documentos</span>
            </v-col>
        </v-row>

        <v-list>
            <a v-for="(item, index) in dataset.docs" :key="index" :href="item.doc_url"
                class="pa-0 d-flex  flex-column align-start list-item-link mb-2" target="_blank">
                <v-col class="pa-0">
                    <v-divider class="primary"></v-divider>
                    <v-list-item class="d-flex align-center">
                        <v-list-item-avatar>
                            <!-- <v-img src="@/assets/Logo-Brizola-Japur-Small.jpg"></v-img> -->
                            <v-img src="@/assets/logo-123milhas.png"></v-img>
                        </v-list-item-avatar>
                        <v-list-item-content>
                            <v-list-item-title class="text-uppercase font-weight-medium mediumGray--text">{{
                                item.name
                                }}</v-list-item-title>
                        </v-list-item-content>
                        <v-icon>mdi-download</v-icon>
                    </v-list-item>
                </v-col>
            </a>
            <v-divider class="primary"></v-divider>
        </v-list>

        <!-- <v-row>
            <v-col>
                <v-card v-for="(item, index) in dataset.docs" :key="index" elevation="2" class="mt-4 mb-4"
                    style="cursor: pointer;" :href="item.doc_url" target="_blank">
                    <v-card-text>
                        <v-row align="center">
                            <div class="py-2 ml-4">
                                <v-icon size="30" class="mr-4" color="primary">mdi-file-document-outline</v-icon>
                                <span class="document-card-title">{{ item.name }}</span>
                            </div>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row> -->

    </v-container>
</template>

<script>
import 'moment/locale/pt-br';

import axios from "axios"

export default {
    data: () => ({
        user: null,
        dataset: {
            docs: [],
            description: '',
            loading: false,
        },
        documents: [
            { name: "Petição inicial", link: "https://assets.rj123milhas.com.br/juridico/1_peticao_inicial.pdf" },
            { name: "Decisão de deferimento do processamento", link: "https://assets.rj123milhas.com.br/juridico/2_decisao_deferimento_processamento.pdf" },
        ]
    }),

    mounted: function () {
    },

    created() {
        this.load();
        this.loadText();

        this.$ga.event({
            eventCategory: 'page',
            eventAction: 'action',
            eventLabel: 'click',
            eventValue: 'about'
        })
    },

    methods: {

        async load() {

            this.dataset.loading = true;

            try {

                let type = 2 //Relatório de Atividades

                const result = await axios.get(`/api/client-docs/list-docs-type`, { params: { type: type} });

                this.dataset.docs = result.data.content.data;

                // this.dataset.docs = [
                //     {
                //         "id": 2,
                //         "name": "01. RMA - JUL. A DEZ.2023",
                //         "order": 1,
                //         "file": "monthly-report-bd2fdac8-553a-449c-90a9-a97d35f19c2d",
                //         "created_at": "2024-05-09 14:13:56",
                //         "updated_at": "2024-07-26 18:37:18",
                //         "file_url": "https://rj123milhas.nyc3.digitaloceanspaces.com/monthly-report-bd2fdac8-553a-449c-90a9-a97d35f19c2d"
                //     },
                //     {
                //         "id": 3,
                //         "name": "02. RMA - JAN. A MAR.2024",
                //         "order": 2,
                //         "file": "monthly-report-e2e4b30f-d4e2-4824-b8f3-6bd8f0d7c7e4",
                //         "created_at": "2024-06-25 13:31:42",
                //         "updated_at": "2024-07-26 18:37:18",
                //         "file_url": "https://rj123milhas.nyc3.digitaloceanspaces.com/monthly-report-e2e4b30f-d4e2-4824-b8f3-6bd8f0d7c7e4"
                //     },
                //     {
                //         "id": 5,
                //         "name": "03. RMA - ABR. E MAI.2024",
                //         "order": 3,
                //         "file": "monthly-report-cd69ef13-6da2-411b-8eed-a21a5055d19b",
                //         "created_at": "2024-07-26 18:35:58",
                //         "updated_at": "2024-07-26 18:37:18",
                //         "file_url": "https://rj123milhas.nyc3.digitaloceanspaces.com/monthly-report-cd69ef13-6da2-411b-8eed-a21a5055d19b"
                //     }
                // ]

            } catch (error) {
                this.$dialog.notify.error(this.$codes(error), {
                    position: 'top-right',
                    timeout: 2000
                })
            } finally {
                this.dataset.loading = false;
            }
        },


        async loadText() {
            try {
                const result = await axios.get(`/api/dynamic-infos/keys`, {
                    params: { keys: 'monthly-reports-description' }
                })

                this.dataset.description = result.data.content && result.data.content.length > 0 ? result.data.content[0].value : '';

            } catch (error) {
                this.$dialog.notify.error(this.$codes(error), {
                    position: 'top-right',
                    timeout: 2000
                })
            }
        },

    }
};
</script>

<style lang="scss">
.title-page {
    color: var(--v-primary-base);
    font-family: Montserrat;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 38px;
}

.document-card-title {
    color: var(--v-primary-base);
    font-weight: 500;
    font-size: 18px;
    line-height: 0px;
}
</style>
