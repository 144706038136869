<template>
  <v-container>
    <v-row>
      <v-col :cols="12">
        <div class="title-container">
          <div class="font-weight-bold mt-10 title-page">Minha Conta</div>
          <div v-if="user" class="button-group">
            <v-btn type="button" elevation="0" color="primary" @click="handleSave" :loading="dataset.loading">
              Atualizar
            </v-btn>
            <v-btn id="btnBack" type="button" elevation="0" color="primary" outlined to="/minha-conta">Voltar</v-btn>
          </div>
        </div>
      </v-col>
    </v-row>

    <br />

    <div class="mt-4" v-if="dataset.loading">
      <v-progress-circular indeterminate color="primary" :size="50"></v-progress-circular>
    </div>

    <div v-if="!dataset.loading" v-html="!user ? dataset.contentUserAnonymous : ''"></div>

    <v-form v-if="user" ref="form" v-model="validForm">
      <v-row>
        <v-col cols="12">
          <v-card class="card-form-outlined">
            <v-card-title class="card-group-title">Editar</v-card-title>
            <v-card-text>
              <v-row>
                <v-col cols="12" sm="6">
                  <v-text-field dense id="name" label="Nome completo" v-model="dataset.data.name" required
                    :rules="[$rules.required]" outlined hide-details="auto">
                  </v-text-field>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-text-field dense label="Telefone" v-model="dataset.data.phone_number" required outlined
                    :rules="[$rules.required]" hide-details="auto">
                  </v-text-field>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <v-row text-align="center">
        <v-col cols="12">
          <v-card class="card-form-outlined">
            <div class="title-container">
              <v-card-title class="card-group-title">Procurador</v-card-title>
              <template v-if="userLawyerData && userLawyerData.lawyer &&
                userLawyerData.lawyer.name &&
                userLawyerData.lawyer.email &&
                userLawyerData.lawyer.federal_registration &&
                userLawyerData.lawyer.federal_registration_uf">
                <v-btn type="button" elevation="0" color="error" class="mt-3" @click="removeProcurator">
                  Remover Procurador
                </v-btn>
              </template>
              <template v-else>
                <v-btn v-if="!showAddProcuratorForm" type="button" elevation="0" color="success"
                  @click="showAddProcuratorForm = true" class="mt-3">Cadastrar</v-btn>
              </template>
            </div>

            <template v-if="showAddProcuratorForm">
              <v-row>
                <v-col cols="12">
                  <v-card class="card-form-outlined">
                    <v-card-text>
                      <v-row>
                        <v-col>
                          <v-text-field dense id="new_procurator_name" label="Nome procurador"
                            v-model="newProcurator.name" required :rules="[$rules.required]" outlined
                            hide-details="auto">
                          </v-text-field>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col>
                          <v-text-field dense id="new_procurator_federal_registration" label="Número OAB"
                            v-model="newProcurator.federal_registration" required :rules="[$rules.required]" outlined
                            hide-details="auto">
                          </v-text-field>
                        </v-col>
                        <v-col>
                          <v-autocomplete dense required :items="dataset.statesList" label="OAB UF"
                            v-model="newProcurator.federal_registration_uf" item-value="name" item-text="name"
                            :rules="[$rules.required]" outlined hide-details="auto">
                          </v-autocomplete>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col>
                          <v-text-field dense id="new_procurator_email" label="E-mail" v-model="newProcurator.email"
                            required :rules="[$rules.required, $rules.email]" outlined hide-details="auto">
                          </v-text-field>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="3">
                          <v-file-input dense accept="image/png, image/jpeg, application/pdf" outlined counter required
                            :rules="[$rules.required, $rules.fileSize(4)]" show-size
                            hint="O arquivo não pode ser superior a 4MB" hide-details="auto" label="Documento OAB"
                            :clearable="true" @change="onNewProcuratorFileSelected"
                            ref="new_procurator_file"></v-file-input>
                        </v-col>
                        <v-col cols="3">
                          <v-file-input dense accept="image/png, image/jpeg, application/pdf" outlined counter required
                            :rules="[$rules.required, $rules.fileSize(4)]" show-size
                            hint="O arquivo não pode ser superior a 4MB" hide-details="auto"
                            label="Documento procuração" :clearable="true" @change="onNewProcurationFileSelected"
                            ref="new_procuration_file"></v-file-input>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12" class="text-right">
                          <v-btn id="btnSaveProcurator" type="button" elevation="0" color="primary" class="mt-3 ml-3"
                            @click="createProcurator" :loading="loadingProcurator">
                            Salvar Procurador
                          </v-btn>
                          <v-btn type="button" elevation="0" color="error" class="mt-3 ml-3"
                            @click="cancelProcuratorCreation">
                            Cancelar
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </template>


            <v-card-text>
              <template v-if="userLawyerData && userLawyerData.lawyer &&
                userLawyerData.lawyer.name &&
                userLawyerData.lawyer.email &&
                userLawyerData.lawyer.federal_registration &&
                userLawyerData.lawyer.federal_registration_uf">
                <v-row>
                  <v-col>
                    <v-text-field dense id="procurator_name" label="Nome procurador"
                      v-model="dataset.data.procurator_name" required :rules="[$rules.required]" disabled outlined
                      hide-details="auto">
                    </v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-text-field dense id="procurator_federal_registration" label="Número OAB"
                      v-model="dataset.data.procurator_federal_registration" required :rules="[$rules.required]"
                      disabled outlined hide-details="auto">
                    </v-text-field>
                  </v-col>
                  <v-col>
                    <v-autocomplete disabled dense required :items="dataset.statesList" label="OAB UF"
                      v-model="dataset.data.procurator_federal_registration_uf" item-value="name" item-text="name"
                      :rules="[$rules.required]" outlined hide-details="auto">
                    </v-autocomplete>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-text-field dense id="procurator_email" label="E-mail" v-model="dataset.data.procurator_email"
                      required :rules="[$rules.required]" disabled outlined hide-details="auto">
                    </v-text-field>
                  </v-col>
                </v-row>
              </template>
              <template v-else>
                <v-row>
                  <v-col cols="12" text-align="center">
                    <span v-if="!showAddProcuratorForm" class="main-text">Nenhum procurador encontrado</span>
                  </v-col>
                </v-row>
              </template>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-form>



    <v-card v-if="user" class="card-form-outlined">
      <v-card-text>
        <template v-if="userLawyerData && userLawyerData.lawyer &&
          userLawyerData.lawyer.name &&
          userLawyerData.lawyer.email &&
          userLawyerData.lawyer.federal_registration &&
          userLawyerData.lawyer.federal_registration_uf">
          <v-row>
            <v-col cols="3">
              <v-file-input disabled dense accept="image/png, image/jpeg, application/pdf" prepend-icon="" outlined
                counter required :rules="[$rules.required, $rules.fileSize(4)]" show-size
                hint="O arquivo não pode ser superior a 4MB" hide-details="auto" label="Documento OAB" :clearable="true"
                @change="onProcuratorSelected($event)" ref="procurator_file">
              </v-file-input>
              <v-btn type="button" elevation="0" color="primary" outlined :href="userLawyerData.lawyer.documentUrl"
                target="_blank">Visualizar
                documento
              </v-btn>
            </v-col>
            <v-col cols="3">
              <v-file-input dense accept="image/png, image/jpeg, application/pdf" prepend-icon="" outlined counter
                required :rules="[$rules.required, $rules.fileSize(4)]" show-size
                hint="O arquivo não pode ser superior a 4MB" hide-details="auto" label="Documento procuração"
                :clearable="true" @change="onProcurationSelected($event)" ref="procuration_file">
              </v-file-input>
              <v-btn type="button" elevation="0" color="primary" outlined :href="userLawyerData.documentUrl"
                target="_blank">Visualizar
                documento
              </v-btn>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="3">
              <iframe :src="userLawyerData.lawyer.documentUrl" width="250" height="150" frameborder="0" scrolling="no"
                class="rounded-xl"></iframe>
              <div class="caption-text">DOCUMENTO OAB</div>
            </v-col>
            <v-col cols="3">
              <iframe :src="userLawyerData.documentUrl" width="250" height="150" frameborder="0" scrolling="no"
                class="rounded-xl"></iframe>
              <div class="caption-text">DOCUMENTO PROCURAÇÃO</div>
            </v-col>
          </v-row>
        </template>

      </v-card-text>
    </v-card>


    <v-row v-if="user">
      <v-col cols="12">
        <v-card class="card-form-outlined" color="gray">
          <v-card-title class="card-group-title">
            <div class="title-container">
              <div class="title-block">
                <span id="title" class="table-card-title">Ações</span>
              </div>
            </div>
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="3">
                <v-btn type="button" elevation="0" color="primary" outlined :href="user.documentUrl"
                  target="_blank">Visualizar
                  documento
                </v-btn>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="3">
                <v-file-input dense accept="image/png, image/jpeg, application/pdf, .csv, application/vnd.ms-excel"
                  prepend-icon="" outlined counter required show-size :rules="fileRules"
                  hint="O arquivo não pode ser superior a 150MB" hide-details="auto" label="Trocar documento"
                  :clearable="true" @change="onFileSelected($event)" ref="logoFile" truncate-length="70">
                </v-file-input>
              </v-col>
            </v-row>
            <v-row class="mt-n3">
              <v-col>
                <iframe :src="user.documentUrl" width="250" height="150" frameborder="0" scrolling="no"
                  class="rounded-xl"></iframe>
                <div class="caption-text">DOCUMENTO</div>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>



<script>
import 'moment/locale/pt-br'
import { eventHub } from '@/main'
import currencyTypes from '@/common/data/currency-types.data'
import statesList from "@/common/data/states-list.data";
import axios from 'axios'

export default {
  data: () => ({
    user: null,
    userLawyerData: null,
    data: [],
    validForm: false,
    loading: false,
    dialog: false,
    selectedItem: null,
    dataset: {
      contentUserAnonymous: '',
      contentUserLogged: '',
      currencyTypes: currencyTypes,
      isAssociationCreditor: false,
      userRequestedValue: 0,
      loading: false,
      clients: [],
      data: {
        items: [],
        client: null
      },
      statesList: statesList,
    },
    selectedDoc: null,
    processes: [],
    loadingProcurator: false,
    showAddProcuratorForm: false,
    newProcurator: {
      name: '',
      email: '',
      federal_registration: '',
      federal_registration_uf: '',
    },
    newProcuratorFile: null,
    newProcurationFile: null,
  }),

  created() {
    this.$ga.event({
      eventCategory: 'page',
      eventAction: 'action',
      eventLabel: 'click',
      eventValue: 'user-area'
    })

    this.loadData()
  },

  computed: {
    fileRules() {
      const rules = [];
      if (!this.dataset.data.documentUrl) {

        rules.push(this.$rules.required);
      }
      rules.push(this.$rules.fileSize(150));
      return rules;
    },
  },

  methods: {
    async loadData() {
      this.dataset.loading = true

      try {
        await this.loadText()
        // await this.loadClients();

        if (this.$root.user) {
          await this.verifyAssociation()
          await this.loadUserInfo()
          await this.load()
        }
      } catch (error) {
        this.$dialog.notify.error(this.$codes(error), {
          position: 'top-right',
          timeout: 2000
        })
      } finally {
        this.dataset.loading = false
      }
    },

    async loadText() {
      const result = await axios.get(`/api/dynamic-infos/keys`, {
        params: { keys: 'user-event-anonymous,user-event-logged' }
      })

      if (!result.data.content) return

      let contentUserAnonymous = result.data.content.find(x => x.key === 'user-event-anonymous')
      let contentUserLogged = result.data.content.find(x => x.key === 'user-event-logged')

      this.dataset.contentUserAnonymous = contentUserAnonymous ? contentUserAnonymous.value : ''
      this.dataset.contentUserLogged = contentUserLogged ? contentUserLogged.value : ''
    },

    async checkAccess() {
      try {
        const response = await axios.get(`/api/users/check-access`);

      } catch (error) {

        if (error.response && error.response.status === 403) {

          this.$router.replace('/minha-conta');
          return;
        }

        this.$dialog.notify.error(this.$codes(error), {
          position: 'top-right',
          timeout: 2000
        });
      }
    },

    async createProcurator() {
      if (!this.$refs.form.validate()) return;

      const confirm = await this.$dialog.confirm({
        text: "Deseja criar ou vincular o procurador?",
        title: "Criar/Vincular Procurador",
        actions: { false: "Cancelar", true: "Confirmar" },
      });

      if (!confirm) return;

      this.loadingProcurator = true;

      const payload = this.buildProcuratorPayload();

      try {
        const response = await axios.post("/api/users/lawyer", payload, {
          headers: { "Content-Type": "multipart/form-data" },
        });

        this.$dialog.notify.success(response.data.message, {
          position: "top-right",
          timeout: 3000,
        });

        await this.loadUserInfo();
        this.showAddProcuratorForm = false;
        this.resetNewProcuratorForm();
        location.reload();
      } catch (error) {
        this.$dialog.notify.error("Erro ao criar ou vincular o procurador.", {
          position: "top-right",
          timeout: 3000,
        });
      } finally {
        this.loadingProcurator = false;
      }
    },

    buildProcuratorPayload() {
      const formData = new FormData();

      formData.append('procurator_name', this.newProcurator.name);
      formData.append('procurator_email', this.newProcurator.email);
      formData.append('procurator_federal_registration', this.newProcurator.federal_registration);
      formData.append('procurator_federal_registration_uf', this.newProcurator.federal_registration_uf);

      if (this.newProcuratorFile) {
        formData.append('procurator_file', this.newProcuratorFile);
      }
      if (this.newProcurationFile) {
        formData.append('procuration_file', this.newProcurationFile);
      }

      return formData;
    },

    onNewProcuratorFileSelected(file) {
      this.newProcuratorFile = file;
    },
    onNewProcurationFileSelected(file) {
      this.newProcurationFile = file;
    },

    resetNewProcuratorForm() {
      this.newProcurator = {
        name: '',
        email: '',
        federal_registration: '',
        federal_registration_uf: '',
      };
      this.newProcuratorFile = null;
      this.newProcurationFile = null;
      if (this.$refs.new_procurator_file) {
        this.$refs.new_procurator_file.reset();
      }
      if (this.$refs.new_procuration_file) {
        this.$refs.new_procuration_file.reset();
      }
    },

    cancelProcuratorCreation() {
      this.showAddProcuratorForm = false;
      this.resetNewProcuratorForm();
    },

    async removeProcurator() {
      const confirm = await this.$dialog.confirm({
        text: "Deseja realmente remover o vínculo com o procurador?",
        title: "Remover Vínculo",
        actions: { false: "Cancelar", true: "Confirmar" },
      });

      if (!confirm) return;

      this.loading = true;

      try {
        await axios.delete("/api/users/lawyer");

        this.$dialog.notify.success("Vínculo removido com sucesso!", {
          position: "top-right",
          timeout: 3000,
        });

        await this.loadUserInfo();
      } catch (error) {
        this.$dialog.notify.error("Erro ao remover o vínculo do procurador.", {
          position: "top-right",
          timeout: 3000,
        });
      } finally {
        this.loading = false;
      }
    },

    openDoc(doc) {
      window.open(doc, '_blank')
    },

    async verifyAssociation() {
      const result = await axios.get('/api/user-creditors/association')

      const data = result.data.content

      if (!data || data.length === 0) return

      this.dataset.isAssociationCreditor = true
      this.dataset.userRequestedValue = data[0].amount
    },

    async loadUserInfo() {
      try {
        const result = await axios.get('/api/users/info');
        this.user = result.data.content;

        if (this.user.lawyers_user && this.user.lawyers_user.length > 0) {
          //console.log("Dados de lawyers_user recebidos:", this.user.lawyers_user);
          this.userLawyerData = this.user.lawyers_user[0];
          //console.log("Dados de lawyer dentro de lawyers_user:", this.userLawyerData.lawyer);
        } else {
          //console.log("Nenhum procurador associado encontrado.");
          this.userLawyerData = null;
        }

      } catch (error) {
        console.error("Erro ao carregar informações do usuário:", error);
      }
    },

    async load() {
      try {
        const result = await axios.get('/api/users/info');
        this.user = result.data.content;
        //console.log("Payload do backend - Usuário:", this.user);

        this.dataset.data.name = this.user.name || '';
        this.dataset.data.phone_number = this.user.phone_number || '';
        this.dataset.data.documentUrl = this.user.documentUrl || '';

        if (this.dataset.data.documentUrl) {
          const fileName = this.dataset.data.documentUrl.split('/').pop();
          //console.log("Nome do arquivo enviado para o banco de dados:", fileName);
        }

        if (this.user.lawyers_user && this.user.lawyers_user.length > 0) {
          //console.log("Dados de lawyers_user recebidos:", this.user.lawyers_user);
          this.userLawyerData = this.user.lawyers_user[0];

          this.dataset.data.procurator_name = this.userLawyerData.lawyer.name || '';
          this.dataset.data.procurator_email = this.userLawyerData.lawyer.email || '';
          this.dataset.data.procurator_federal_registration = this.userLawyerData.lawyer.federal_registration || '';
          this.dataset.data.procurator_federal_registration_uf = this.userLawyerData.lawyer.federal_registration_uf || '';
          this.dataset.data.procurator_file = this.userLawyerData.lawyer.documentUrl || '';
          this.dataset.data.procuratorion_file = this.userLawyerData.documentUrl || '';

          if (this.dataset.data.procurator_file) {
            const lawyerFileName = this.dataset.data.procurator_file.split('/').pop();
            //console.log("Nome do arquivo associado ao procurador:", lawyerFileName);
          }
        } else {
          //console.log("Nenhum procurador associado encontrado.");
          this.userLawyerData = {
            lawyer: {
              name: '',
              email: '',
              federal_registration: '',
              federal_registration_uf: '',
              documentUrl: '',
            },
          };
        }

        //console.log("Finalizou o load com os dados atualizados:", this.dataset.data);

      } catch (error) {
        console.error("Erro ao carregar informações do usuário:", error);
      }
    },

    handleSave() {
      if (this.showAddProcuratorForm) {
        this.$dialog.notify.error("Você deve salvar as informações do procurador primeiro.", {
          position: "top-right",
          timeout: 3000,
        });
        return;
      }

      this.updateUser();
    },

    async updateUser() {
      if (!this.$refs.form.validate()) return;

      const confirm = await this.$dialog.confirm({
        text: "Deseja salvar as alterações?",
        title: "Atualizar Informações",
        actions: {
          false: "Cancelar",
          true: "Salvar",
        },
      });

      if (!confirm) return;

      this.dataset.loading = true;

      const payload = this.buildPayload();

      //console.log("Payload configurado:", [...payload.entries()]);

      try {
        await axios.patch("/api/users", payload, {
          headers: { "Content-Type": "multipart/form-data" },
        });
        //console.log("Payload enviado com sucesso.");
        this.$dialog.notify.success("Dados atualizados com sucesso!", {
          position: "top-right",
          timeout: 3000,
        });

        await this.loadUserInfo();
      } catch (error) {
        console.error("Erro ao atualizar os dados:", error);

        this.$dialog.notify.error("Erro ao atualizar os dados.", {
          position: "top-right",
          timeout: 3000,
        });
      } finally {
        this.dataset.loading = false;
      }
    },

    buildPayload() {
      const formData = new FormData();

      formData.append('name', this.dataset.data.name);
      formData.append('phone_number', this.dataset.data.phone_number);

      if (this.dataset.data.file) {
        formData.append('file', this.dataset.data.file);
      }

      if (this.showAddProcuratorForm) {
        formData.append('procurator_name', this.dataset.data.procurator_name);
        formData.append('procurator_email', this.dataset.data.procurator_email);
        formData.append('procurator_federal_registration', this.dataset.data.procurator_federal_registration);
        formData.append('procurator_federal_registration_uf', this.dataset.data.procurator_federal_registration_uf);

        if (this.dataset.data.procurator_file) {
          formData.append('procurator_file', this.dataset.data.procurator_file);
        }
        if (this.dataset.data.procuration_file) {
          formData.append('procuration_file', this.dataset.data.procuration_file);
        }
      }

      return formData;
    },

    onFileSelected(file) {
      this.dataset.data.file = file;
    },
    onProcurationSelected(file) {
      this.dataset.data.procuration_file = file;
    },
    onProcuratorSelected(file) {
      this.dataset.data.procurator_file = file;
    }

  }
}
</script>

<style lang="scss">
.title-page {
  color: var(--v-primary-base);
  font-family: 'Raleway';
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: 38px;
}

.section-info-title {
  color: var(--v-primary-base);
  font-family: 'Raleway';
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
}

.section-info-value {
  font-family: 'Raleway';
  font-size: 14px;
  font-style: normal;
}

.custom-panel-header {
  min-height: 20px !important;
  line-height: 20px !important;
  padding-top: 5 !important;
  padding-bottom: 0 !important;
}
</style>
