import Vue from "vue";
import VueRouter from "vue-router";
import Home from "@/views/Home.vue";
import About from "@/views/About.vue";
import Team from "@/views/Team.vue";
import FAQ from "@/views/FAQ.vue";
import UserMyAccountView from "@/views/UserMyAccountView.vue";
import UserMyAccountEdit from "@/views/UserMyAccountEdit.vue";
import UserEventView from "@/views/UserEventView.vue";
import UserEventCreate from "@/views/UserEventCreate.vue";
import UserEventEdit from "@/views/UserEventEdit.vue";
import ClientList from "@/views/ClientList.vue";
import MonthlyReport from "@/views/MonthlyReport.vue";
import MainDocuments from "@/views/MainDocuments.vue";
import Contact from "@/views/Contact.vue";

import QuestionsList from "@/views/QuestionsList.vue";

import DocumentsTemplates from "@/views/DocumentsTemplates.vue";
import DocumentCenterPage from "@/views/DocumentCenterPage.vue";

//the route guard
const guard = function (to: any, from: any, next: any) {
  const token = localStorage.getItem("token");

  const publicPath = to.matched.find(
    (path) => path.name === "Public" || path.name === "Home"
  );
  if (!token && to.path !== "/login" && !publicPath) {
    next({
      path: "/home",
    });
  } else {
    next();
  }
};

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    component: () => import("@/layouts/Public.vue"),
    children: [
      {
        path: "/home",
        name: "home",
        component: Home,
      },
      // {
      //   path: "/equipe",
      //   name: "team",
      //   component: Team,
      // },
      {
        path: "/minha-conta",
        name: "userMyAccountView",
        component: UserMyAccountView,
        beforeEnter: guard,
      },
      {
        path: "/minha-conta-editar",
        name: "userMyAccountEdit",
        component: UserMyAccountEdit,
        beforeEnter: guard,
      },
      {
        path: "/habilitacoes-divergencias",
        name: "userEventview",
        component: UserEventView,
      },
      {
        path: "/habilitacoes-divergencias/novo",
        name: "UserEventCreate",
        component: UserEventCreate,
      },
      {
        path: "/habilitacoes-divergencias/:id",
        name: "UserEventEdit",
        component: UserEventEdit,
      },
      {
        path: "/modelos-documentos",
        name: "documentstemplates",
        component: DocumentsTemplates,
      },
      {
        path: "/central-documentos",
        name: "DocumentCenterPage",
        component: DocumentCenterPage,
      },
      {
        path: "/lista-123milhas",
        name: "clientlist",
        component: ClientList,
      },
      {
        path: "/sobre-processo",
        name: "about",
        component: About,
      },
      {
        path: "/relatorios-mensais",
        name: "monthlyreport",
        component: MonthlyReport,
      },
      {
        path: "/principais-documentos",
        name: "MainDocuments",
        component: MainDocuments,
      },
      {
        path: "/duvidas",
        name: "QuestionsList",
        component: QuestionsList,
      },
      // {
      //   path: "/faq",
      //   name: "faq",
      //   component: FAQ,
      // },
      {
        path: "/contato",
        name: "contact",
        component: Contact,
      },
      {
        path: "/",
        redirect: "/home",
      },
    ],
  },
];

const router = new VueRouter({
  base: process.env.BASE_URL,
  routes,
});


export default router;
