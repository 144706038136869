<template>
  <v-container>
    <v-row>
      <v-col :cols="12">
        <div class="title-container">
          <div class="font-weight-bold mt-10 title-page">Minha Conta</div>
          <div v-if="user" class="button-group">
            <v-btn type="button" elevation="0" color="primary" to="/minha-conta-editar">Editar</v-btn>
            <v-btn id="btnBack" type="button" elevation="0" color="primary" outlined to="/home">Voltar</v-btn>
          </div>
        </div>
      </v-col>
    </v-row>

    <br />

    <div class="mt-4" v-if="dataset.loading">
      <v-progress-circular indeterminate color="primary" :size="50"></v-progress-circular>
    </div>

    <div v-if="!dataset.loading" v-html="!user ? dataset.contentUserAnonymous : ''"></div>


    <v-row v-if="user">
      <v-col cols="12">
        <v-card class="card-form-outlined">
          <v-card-title class="card-group-title">Detalhes</v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="12" sm="4">
                <span class="main-text"> {{ user ? user.name : '' }} </span>
                <div class="caption-text">NOME COMPLETO</div>
              </v-col>
              <v-col cols="12" sm="4">
                <span class="main-text"> {{ user ? user.phone_number : '' }} </span>
                <div class="caption-text">TELEFONE</div>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row v-if="user" text-align="center">
      <v-col cols="12">
        <v-card class="card-form-outlined">
          <v-card-title class="card-group-title">Procurador</v-card-title>
          <v-card-text>
            <template v-if="userLawyerData && userLawyerData.lawyer">
              <v-row>
                <v-col>
                  <v-row>
                    <v-col cols="12" sm="4">
                      <span class="main-text">{{ userLawyerData.lawyer.name || 'Nome não disponível' }}</span>
                      <div class="caption-text">NOME DO PROCURADOR</div>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="4">
                  <span class="main-text">{{ userLawyerData.lawyer.federal_registration || 'Nome não disponível'
                    }}</span>
                  <div class="caption-text">NÚMERO OAB</div>
                </v-col>
                <v-col cols="12" sm="4">
                  <span class="main-text">{{ userLawyerData.lawyer.federal_registration_uf || 'Nome não disponível'
                    }}</span>
                  <div class="caption-text">OAB UF</div>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="4">
                  <span class="main-text">{{ userLawyerData.lawyer.email || 'Nome não disponível' }}</span>
                  <div class="caption-text">E-MAIL</div>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="3">
                  <v-btn type="button" elevation="0" color="primary" outlined :href="userLawyerData.lawyer.documentUrl"
                    target="_blank">
                    Visualizar documento
                  </v-btn>
                </v-col>
                <v-col cols="3">
                  <v-btn type="button" elevation="0" color="primary" outlined :href="userLawyerData.documentUrl"
                    target="_blank">
                    Visualizar documento
                  </v-btn>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="3">
                  <iframe :src="userLawyerData.lawyer.documentUrl" width="250" height="150" frameborder="0"
                    scrolling="no" class="rounded-xl"></iframe>
                  <div class="caption-text">DOCUMENTO OAB</div>
                </v-col>
                <v-col cols="3">
                  <iframe :src="userLawyerData.documentUrl" width="250" height="150" frameborder="0" scrolling="no"
                    class="rounded-xl"></iframe>
                  <div class="caption-text">DOCUMENTO PROCURAÇÃO</div>
                </v-col>
              </v-row>
            </template>
            <template v-else>
              <v-row>
                <v-col cols="12" text-align="center">
                  <span class="main-text">Nenhum procurador encontrado</span>
                </v-col>
              </v-row>
            </template>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>


    <v-row v-if="user">
      <v-col cols="12">
        <v-card class="card-form-outlined" color="gray">
          <v-card-title class="card-group-title">
            <div class="title-container">
              <div class="title-block">
                <span id="title" class="table-card-title">Ações</span>
              </div>
            </div>
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col>
                <v-btn type="button" elevation="0" color="primary" outlined :href="user.documentUrl"
                  target="_blank">Visualizar
                  documento
                </v-btn>
              </v-col>
            </v-row>
            <v-row class="mt-n3">
              <v-col>
                <iframe :src="user.documentUrl" width="250" height="150" frameborder="0" scrolling="no"
                  class="rounded-xl"></iframe>
                <div class="caption-text">DOCUMENTO</div>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>


<script>
import 'moment/locale/pt-br'
import { eventHub } from '@/main'
import axios from 'axios'

export default {
  data: () => ({
    user: null,
    userLawyerData: null,
    loading: false,
    dataset: {
      contentUserAnonymous: '',
      contentUserLogged: '',
      loading: false,
      data: {
        items: [],
        client: null
      }
    },
    selectedDoc: null,
    dialogViewDoc: false,
    processes: []
  }),

  created() {
    this.$ga.event({
      eventCategory: 'page',
      eventAction: 'action',
      eventLabel: 'click',
      eventValue: 'user-area'
    })

    this.loadData()
  },

  methods: {
    async loadData() {
      this.dataset.loading = true

      try {
        await this.loadText()
        if (this.$root.user) {
          await this.verifyAssociation()
          await this.loadUserInfo()
          await this.load()
        }
      } catch (error) {
        this.$dialog.notify.error(this.$codes(error), {
          position: 'top-right',
          timeout: 2000
        })
      } finally {
        this.dataset.loading = false
      }
    },

    async loadText() {
      const result = await axios.get(`/api/dynamic-infos/keys`, {
        params: { keys: 'user-event-anonymous,user-event-logged' }
      })

      if (!result.data.content) return

      let contentUserAnonymous = result.data.content.find(x => x.key === 'user-event-anonymous')
      let contentUserLogged = result.data.content.find(x => x.key === 'user-event-logged')

      this.dataset.contentUserAnonymous = contentUserAnonymous ? contentUserAnonymous.value : ''
      this.dataset.contentUserLogged = contentUserLogged ? contentUserLogged.value : ''
    },

    async checkAccess() {
      try {
        const response = await axios.get(`/api/users/check-access`);

      } catch (error) {

        if (error.response && error.response.status === 403) {

          this.$router.replace('/minha-conta');
          return;
        }

        this.$dialog.notify.error(this.$codes(error), {
          position: 'top-right',
          timeout: 2000
        });
      }
    },

    openDoc(doc) {
      window.open(doc, '_blank')
    },

    async verifyAssociation() {
      const result = await axios.get('/api/user-creditors/association')

      const data = result.data.content

      if (!data || data.length === 0) return

      this.dataset.isAssociationCreditor = true
      this.dataset.userRequestedValue = data[0].amount
    },


    async loadUserInfo() {
      try {
        const result = await axios.get('/api/users/info');
        this.user = result.data.content;

        if (this.user.lawyers_user && this.user.lawyers_user.length > 0) {
          //console.log("Dados de lawyers_user recebidos:", this.user.lawyers_user);
          this.userLawyerData = this.user.lawyers_user[0];
          //console.log("Dados de lawyer dentro de lawyers_user:", this.userLawyerData.lawyer);
        } else {
          //console.log("Nenhum procurador associado encontrado.");
          this.userLawyerData = null;
        }

      } catch (error) {
        console.error("Erro ao carregar informações do usuário:", error);
      }
    },


    async loadLawyer() {
      try {
        const response = await axios.get('/api/user/laywer');
        this.lawyer = response.data.content;

        //console.log("Dados do procurador:", this.lawyer);

      } catch (error) {
        console.error("Erro ao carregar informações do procurador:", error);
        this.lawyer = null;
      }
    },

    async load() {
      const result = await axios.get('/api/user-events/request')
      this.data = result.data.content
      this.processes = []
      this.data.forEach(item => {
        if (Array.isArray(item.processes) && item.processes.length > 0) {
          this.processes = [...this.processes, ...item.processes]
        }
      })
    },
  }
}
</script>

<style lang="scss">
.title-page {
  color: var(--v-primary-base);
  font-family: 'Raleway';
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: 38px;
}

.section-info-title {
  color: var(--v-primary-base);
  font-family: 'Raleway';
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
}

.section-info-value {
  font-family: 'Raleway';
  font-size: 14px;
  font-style: normal;
}

.custom-panel-header {
  min-height: 20px !important;
  line-height: 20px !important;
  padding-top: 5 !important;
  padding-bottom: 0 !important;
}

.main-text {
  font-size: 16px;
  /* Tamanho do texto principal */
  color: #000;
  /* Cor do texto */
}

.caption-text {
  font-size: 12px;
  /* Tamanho da legenda menor */
  font-weight: bold;
  /* Negrito na legenda */
  color: #444;
  /* Cor da legenda */
  margin-top: -5px;
  /* Ajuste de espaçamento entre texto e legenda */
}
</style>
